@import 'Theme.scss';
@import url('https://fonts.cdnfonts.com/css/snell-roundhand');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@font-face {
    font-family: 'Mango';
    src: local('Mango'), url(./font/Mango.woff) format('woff');
  }

:root {
    background-image: url("https://i.imgur.com/crKpCYl.png"),url("https://i.imgur.com/v5OuMaR.png");
    background-position: left top, right top;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: 25% 65%;
    /*30% 80%*/
    /*Nice corner flower: https://i.pinimg.com/originals/ad/9c/40/ad9c402ca2a5dbac1ce4ead1e5f0e1f9.png
    https://i.imgur.com/154HbQt.png //Flipped flower
    Pink Wallpaper corner: https://thumbnails.production.thenounproject.com/e-3LvRIVysDS343teZDVResBuHA=/fit-in/1000x1000/photos.production.thenounproject.com/photos/E3DE3D3A-341F-4629-A1ED-BCAF80AF7F0B.jpg
    */
    font-size: 16px;
    font-family: 'Cormorant Garamond', serif;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    /*border: solid 8px theme-color-var(darkbeige);*/

    @include set-theme-vars(light);

    background-color: theme-color-var(base);
    color: theme-color-var(primary);

    *::selection {
        background: theme-color-var(selection);
    }

    .is-color-primary {
        color: theme-color-var(primary);
    }

    .is-color-secondary {
        color: theme-color-var(secondary);
    }

    .is-color-tertiary {
        color: theme-color-var(tertiary);
    }
}


  
*, *::before, *::after {
    box-sizing: border-box;
}

body {
    min-width: 280px;
    max-width: 960px;
    margin: 0 auto;
    padding: 1.5rem;
}

.escort-card{
    background-color: theme-color-var(base);
}

h1,h2,h3{
    font-family: 'Snell Roundhand', sans-serif !important
}

.credits{
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    padding: 10px 0;
}

.name{
    font-family: 'Mango' !important;
    color: theme-color-var(darkbeige);
}

.table-text{
    font-family: 'Lora', serif !important; 
    text-transform: uppercase !important;
    letter-spacing: .2rem;
    color: black;

}

.table-number{
    font-family: 'Mango' !important;
    color: black;
}

.hide{
    display: none;
}

#wIMG{
    width: 900px;
    height: auto;
}


.name-input{
    font-family: 'Lora', serif !important;
    text-transform: uppercase !important;
    letter-spacing: .2rem;
}

input [type=text]{
    border: 2px solid theme-color-var(lightbeige);
    color: theme-color-var(darkbeige);
}

input[type=text]:focus {
    border: 2px solid theme-color-var(lightbeige);
    color: theme-color-var(darkbeige);
  }

::placeholder{
    color: theme-color-var(darkbeige);
}

@media only screen and (max-device-width: 1000px) {
    :root{
    background-image: url("https://i.imgur.com/crKpCYl.png"),url("https://i.imgur.com/v5OuMaR.png");
    background-position: left top, right top;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: 150px 200px;
    /*background-size: 30% 20%;*/
    }
    #wIMG{
        width: 330px;
        height: auto;
    }
    input.is-size-4{
    font-size: 0.8rem !important;
}
  }