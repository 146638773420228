@import '../../Theme.scss';

.name-search {
    input {
        display: block;
        width: 75%;
        background-color: theme-color-var(base);
        outline: none;
        /*border: 2px solid theme-color-var(tertiary);*/
        border: 2px solid theme-color-var(darkbeige);
        border-radius: 4px;
        padding: 1rem;
        font-family: inherit;

        transition-duration: 0.2s;
        transition-property: border-color;

        &:focus, &:active {
            /*border-color: theme-color-var(secondary);*/
            border-color:theme-color-var(darkbeige);
        }
    }

    button {
        display: block;
        background-color: theme-color-var(base);
        border: none;
        outline: none;
        box-shadow: 0px 0px 0px 2px theme-color-var(darkbeige);
        /*box-shadow: 0px 0px 0px 2px theme-color-var(tertiary);*/
        transition: box-shadow 0.6s linear;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        font-family: inherit;

        transition-duration: 0.2s;
        transition-property: color, box-shadow;

        color: theme-color-var(secondary);
        outline-color: theme-color-var(tertiary);

        &:hover {
            color: theme-color-var(primary);
            box-shadow: 0px 0px 0px 2px theme-color-var(secondary);
        }

        &:focus {
            box-shadow: 0px 0px 0px 2px theme-color-var(secondary);
        }
        
        &:active {
            color: theme-color-var(primary);
            box-shadow: 0px 0px 0px 6px theme-color-var(primary);
        }

        &.name-submit {
            padding: 1rem 2rem;
        }

        &.random {
            padding: 0.5rem 1rem;
        }
    }

    hr {
        border: none;
        /*background-color: theme-color-var(tertiary);*/
        background-color: theme-color-var(lightbeige);
        height: 2px;
    }
}